/* PRODUCTS PAGE */
.products {
  padding: 6.4rem 0;
  background-color: var(--color-grey-light-1);
}

/* .product-logo {
  height: 9.6rem;
  margin: 2rem 0;
} */

.image {
  width: 100%;
  border-radius: 2px;
}

.summary {
  padding: 0.4rem 0;
}

.color {
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
}

.usage {
  font-size: 1.4rem;
  color: var(--color-grey-dark-1);
}

.productName {
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  /* letter-spacing: 0.25px; */
  padding: 1.2rem 0;
  /* height: 1.8em; */
}

.logo {
  margin-bottom: 4rem;
  text-align: center;
}

.logo img {
  height: 8rem;
}

.link {
  text-decoration: none;
  color: var(--color-grey-dark-1);
}

/* .product-description {
  font-size: 1.4rem;
  color: var(--color-grey-dark-1);
  line-height: 1.3;
}

.product-card {
  font-size: 1.8rem;
  line-height: 1.5;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-light-2);
  box-shadow: inset 0 0 1.2rem rgba(0, 0, 0, 0.05), 0 0 0 4px var(--color-white),
    0 0 2.4rem rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  transition: all 0.4s;
} */

/* .product-card:hover {
  transform: translateY(-1.2rem);
  box-shadow: 0 3.2rem 6.4rem rgba(0, 0, 0, 0.06);
} */

/* 
.product-card-summary {
  padding: 0.8rem;
}

.product-color {
  font-weight: 500;
}

.product-color-coating {
  font-size: 1.4rem;
  color: var(--color-grey-dark-1);
}

#color-grid {
  margin-bottom: 4.8rem;
} */

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {
  .logo img {
    height: 6rem;
  }
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
    .logo img {
    height: 4rem;
  }

  /* .color {
  font-size: 1.4rem;
  font-weight: 600;
}

.usage {
  font-size: 1.2rem;
} */
}
