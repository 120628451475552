.footer {
  max-width: 130rem;
  padding: 6rem 0;
  margin: 0 auto;
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--color-grey-dark-2);
  text-align: center;
}

.logo {
  margin-bottom: 4rem;
  height: 8rem;
}

.gridFooter {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: left;
  column-gap: 20rem;
}

.contactColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.2rem;
  border-top: 1px solid var(--color-grey-dark-2);
  padding-top: 1rem;
}

.contacts {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.navColumn {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--color-grey-dark-2);
  padding-top: 1rem;
  justify-content: space-between;
}

.nav {
  list-style: none;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  margin-bottom: 3rem;
}

.copyright {
  font-size: 1.2rem;
  line-height: 1.6;
  color: var(--color-grey-light-3);
  margin-top: 1.2rem;
}

.link:link,
.link:visited {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: left;
  color: var(--color-grey-dark-2);
  text-decoration: none;
  transition: all 0.3s;
}

.link:hover,
.link:active {
  color: var(--color-grey-dark-3);
  font-weight: 700;
}

.icon {
  color: var(--color-primary);
  font-size: 2rem;
}

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
  .footer {
    max-width: 120rem;
    padding: 4rem 0;
  }

  .gridFooter {
    column-gap: 15rem;
  }

  .logo {
    margin-bottom: 4rem;
    height: 6.4rem;
  }
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
  .gridFooter {
    max-width: 90vw;
    column-gap: 10rem;
    margin: 0 auto;
    text-align: center;
  }

  .contacts {
    /* font-size: 1.4rem; */
  }

  .link:link,
  .link:visited {
    /* font-size: 1.4rem; */
  }

    .copyright {
    /* font-size: 1.2rem; */
  }
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
  .footer {
    padding: 2rem 0;
  }

  .gridFooter {
    column-gap: 5rem;
  }

  .logo {
    margin-bottom: 2rem;
    height: 5.4rem;
  }

  .contacts {
    /* font-size: 1.3rem; */
  }

  .link:link,
  .link:visited {
    /* font-size: 1.3rem; */
  }

  .copyright {
    /* font-size: 1rem; */
  }
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {
  .gridFooter {
    column-gap: 2.5rem;
  }

  .contacts {
    /* font-size: 1.2rem; */
  }

  .link:link,
  .link:visited {
    /* font-size: 1.2rem; */
  }


}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
  .gridFooter {
    display: flex;
    flex-direction: column;
    flex-direction: column-reverse;
  }

  /* .logo {
    margin-bottom: 0;
  } */

  .contactColumn {
    /* flex-direction: row; */
    /* flex-wrap: wrap; */
    border-top: none;
    padding: 0.5rem 0 1rem 0;
  }

  .contacts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .navColumn {
    padding-top: 0.5rem;
  }

  .nav {
    margin-bottom: 3rem;
  }

  .copyright {
    /* font-size: .8rem; */
  }
}
