.gallery {
  padding: 6.4rem 0;
  background-color: var(--color-grey-light-1);
}

.album {
  padding-top: 4rem;
  width: 90vw;
  margin: 0 auto;
  display: grid;
  column-gap: 3rem;
  row-gap: 3rem;
  grid-template-columns: repeat(4, 1fr);
}

.album img {
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
  .album {
    grid-template-columns: repeat(3, 1fr);
  }
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
  .album {
    grid-template-columns: repeat(2, 1fr);
  }
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
  .album {
    grid-template-columns: repeat(1, 1fr);
  }
}
