.nav {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2.4rem;
  /* background-color: rgb(255, 255, 255, 0.75); */
  background-color: var(--color-white);
  /* background: linear-gradient(
    to right bottom,
    var(--color-primary),
    var(--color-secondary)
  ); */
}

.list {
  list-style: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 2.4rem;
  transition: all 0.5s;
}

.link:link,
.link:visited {
  display: inline-block;
  text-decoration: none;
  color: var(--color-primary);
  /* color: var(--color-white); */
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 0.75px;
  transition: all 0.5s ease-out;
}

.link:hover,
.link:active {
  color: var(--color-secondary);
  text-shadow: 1px 1px 2px var(--color-primary);
  font-weight: 800;
  transform: scale(1.2, 1.2);
}

.link.cta:link,
.link.cta:visited {
  padding: 0.8rem 1.6rem;
  border-radius: 6px;
  color: var(--color-white);
  background-color: var(--color-tertiary);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.link.cta:hover,
.link.cta:active {
  color: var(--color-white);
  background-color: var(--color-secondary);
  transform: scale(1.1, 1.1);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
  .nav {
    padding-right: 1.5rem;
  }

  .list {
    gap: 1.5rem;
  }

}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
  .nav {
    background-color: rgba(255, 255, 255, 0.97);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateX(100%);

    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in;

    /* Hide navigation */
    /* Allows NO transitions at all */
    /* display: none; */

    /* 1) Hide it visually */
    opacity: 0;

    /* 2) Make it unaccessible to mouse and keyboard */
    pointer-events: none;

    /* 3) Hide it from screen readers */
    visibility: hidden;
  }

  .nav.navOpen  {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }

  .list {
    flex-direction: column;
  }
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
}
