.rentals {
  padding: 6.4rem 0;
  background-color: var(--color-grey-light-1);
}

.rentalContainer {
  padding: 1.4rem 2.4rem;
}

.image {
  object-fit: cover;
  height: 100%;
  width: 40%;
}

.heading {
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 0;
}

.subheading {
  color: var(--color-grey-dark-1);
  font-size: 1.4rem;
  font-weight: 600;
  /* margin-bottom: .2rem; */
  text-transform: uppercase;
}

.summary {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 1rem 0;
  padding-top: 1rem;
  border-top: 1px solid var(--color-grey-light-2);
}

.link:link,
.link:visited {
  font-size: 1.4rem;
  font-weight: 500;
  display: inline-block;
  color: var(--color-primary);
  text-decoration: none;
  transition: all 0.3s;
}

.link:hover,
.link:active {
  color: var(--color-secondary);
  transform: scale(1.1, 1.1);
}

.list {
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 1rem 0;
  padding-top: 1rem;
  border-top: 1px solid var(--color-grey-light-2);
}

/* .rental-card {
  position: relative;
}

.rental-card::after {
  content: 'Call for Availability';
  position: absolute;
  top: 35px;
  left: -70px;
  width: 250px;
  height: 40px;
  transform: rotate(-45deg);
  background-color: var(--color-tertiary);
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.25px;
  justify-content: center;
  display: flex;
  align-items: center;
} */

/* .action {
  display: inline-flex;
  margin-top: 1rem;
  color: #ffffff;
  font-size: 1.2rem;
  line-height: 1.25rem;
  font-weight: 500;
  align-items: center;
  gap: 0.25rem;
  background-color: #2563EB;
  padding: 4px 8px;
  border-radius: 4px;
} */

/* Button:link,
Button:visited {
  transition: all 0.5s;
}

Button:hover,
Button:active {
  transform: scale(1.2, 1.2);
} */

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
  .summary {
    /* font-size: 1.1rem; */
  }
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
  .summary {
    margin: 0.5rem 0;
  }
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {
  .image {
    width: 35%;
  }

  .summary {
    /* font-size: 1.2rem; */
    margin: 1rem 0;
  }
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
  .rentals {
    padding: 6.4rem 0 0 0;
  }

  .image {
    width: 100%;
  }

    .heading {
    font-size: 2.6rem;
    font-weight: 800;
  }

  .subheading {
    font-size: 1.8rem;
    font-weight: 600;
  }

    .summary {
    font-size: 1.4rem;
  }
}
