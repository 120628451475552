/* 
- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
*/

/* rem and em do NOT depend on html font-size in media queries! Instead, 1rem = 1em = 16px */

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
  .container {
    /* margin: 0 4rem; */
    width: 90vw;
  }

  /*  */

  /* .hero {
    max-width: 120rem;
  } */

  /* .heading-primary {
    font-size: 4.4rem;
  } */
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
  html {
    /* 9px / 16px  */
    font-size: 56.25%;
  }

  .page-header {
    padding: 2em;
  }

  .heading-secondary {
    /* font-size: 3rem; */
  }

  /* .heading-tertiary {
    font-size: 1.8rem;
  } */

  /* .subheading {
    font-size: 1.2rem;
  } */

  .container {
    /* margin: 0 2.4rem; */
  }

  .grid {
    row-gap: 3.2rem;
    column-gap: 2.4rem;
  }
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
  html {
    /* 8px / 16px = 0.5 = 50% */
    font-size: 50%;
  }

  .page-header {
    padding: 1.2em;
  }

  .heading-primary--main {
    font-size: 4.8rem;
  }

  .heading-secondary {
    margin-bottom: 3rem;
  }

  .container {
    padding: 3rem 0;
  }

  .grid--2-cols {
  grid-template-columns: repeat(1, 1fr);
  }

  /* /// */

  .grid--5-cols {
    grid-template-columns: repeat(4, 1fr);
  }

  .header {
    background-color: rgb(255, 255, 255, 0.75);
  }

  .logo-box {
    width: 30%;
  }

  .hero {
    grid-template-columns: 1fr;
    padding: 0 8rem;
    gap: 6.4rem;
  }

  /* .popup-bubble .logo {
    height: 8rem;
  } */
    #map {
    height: 40rem;
  }
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {
  html {
    /* 7px / 16px = 0.437 = 43.5% */
    font-size: 43.5%;
  }

  .page-header {
    padding: 0.8rem;
  }

  .heading-primary--main {
    /* font-size: 3.6rem; */
  }

  .heading-secondary {
    /* font-size: 2.4rem; */
    /* margin-bottom: 2rem; */
  }

  .grid {
    row-gap: 2.4rem;
  }

  .grid--2-cols {
    grid-template-columns: repeat(1, 1fr);
  }

  /* ////// */

  .grid--3-cols,
  .grid--4-cols {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid--5-cols {
    grid-template-columns: repeat(3, 1fr);
  }


}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
  html {
    /* 6px / 16px = 0.375 = 37.5% */
    font-size: 37.5%;
  }

  .paragraph {
    /* font-size: 1.4rem; */
    /* hyphens: auto; */
  }

  /* .container {
    padding: 0 3.6rem;
  } */

  /* .grid {
    row-gap: 2.4rem;
    column-gap: 2.4rem;
  } */

  .grid--2-cols,
  .grid--3-cols,
  .grid--4-cols {
    grid-template-columns: 1fr;
  }

  .grid--5-cols {
    grid-template-columns: repeat(2, 1fr);
  }
}

/**************************/
/* Fixing Safari flexbox-gap */
/**************************/

.no-flexbox-gap .main-nav-list li:not(:last-child) {
  margin-right: 4.8rem;
}

.no-flexbox-gap .list-item:not(:last-child) {
  margin-bottom: 1.6rem;
}

.no-flexbox-gap .list-icon:not(:last-child) {
  margin-right: 1.6rem;
}

.no-flexbox-gap .delivered-faces {
  margin-right: 1.6rem;
}

.no-flexbox-gap .meal-attribute:not(:last-child) {
  margin-bottom: 2rem;
}

.no-flexbox-gap .meal-icon {
  margin-right: 1.6rem;
}

.no-flexbox-gap .footer-row div:not(:last-child) {
  margin-right: 6.4rem;
}

.no-flexbox-gap .social-links li:not(:last-child) {
  margin-right: 2.4rem;
}

.no-flexbox-gap .footer-nav li:not(:last-child) {
  margin-bottom: 2.4rem;
}

@media (max-width: 75em) {
  .no-flexbox-gap .main-nav-list li:not(:last-child) {
    margin-right: 3.2rem;
  }
}

@media (max-width: 59em) {
  .no-flexbox-gap .main-nav-list li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 4.8rem;
  }
}

/* jfjdkd */
