.sectionContact {
  padding-bottom: 6.4rem;
  background: linear-gradient(
    to right bottom,
    var(--color-secondary),
    var(--color-primary)
  );
}

.contactCards {
  justify-items: center;
  padding: 4em 4rem;
  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 52%,
      transparent 50%
    ),
    url(../../public/img/gallery/rentals-2.1.JPEG);
  background-size: cover;
  border-radius: 18px;
  box-shadow: 0 2.4rem 2.4rem rgba(0, 0, 0, 0.2);
  width: 100%;
}

.contact-cards.grid {
  column-gap: 2rem;
}

.contactCard {
  width: 100%;
  padding: 0 1rem;
  overflow: hidden;
  position: relative;
  border-radius: 12px;
}

.toggleMap {
  display: none;
}

/* END CONTACT FORM */
.contactOptionsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 3rem;
  column-gap: 0;
  padding-top: 3.2rem;
}

.contactOption {
  border-left: 1px solid var(--color-grey-dark-1);
  color: var(--color-grey-dark-2);
  padding: 0 9px;
}

.contactOptionHeading {
  font-size: 2rem;
  font-weight: 500;
  /* letter-spacing: 0.25px; */
  margin-bottom: 1rem;
}

.contactText {
  font-size: 1.4rem;
  letter-spacing: 0.25px;
  font-weight: 500;
}

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
    .contactCards {
    background-image: linear-gradient(
        90deg,
        var(--color-grey-light-1) 0%,
        var(--color-grey-light-1) 100%
      ),
      url(../../public/img/gallery/rentals-2.1.JPEG);
  }

  .contactCard  {
    padding-top: 1rem;
  }

  .toggleMap {
    display: block;
  }
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {

}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
  .contactOptionsGrid {
    display: flex;
    flex-direction: column;
  }
}
