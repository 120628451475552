.featured {
  padding: 3.2rem 0;
}

.logos {
  display: flex;
  justify-content: space-evenly;
  gap: 2.4rem;
}

.logos img {
  height: 5.6rem;
  filter: brightness(100);
  opacity: 90%;
}

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
  .logos img {
    height: 4.8rem;
  }
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
    .logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
  .logos img {
    /* height: 3.2rem; */
  }
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
  .logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
}
