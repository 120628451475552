/* START GOOGLE MAPS CARD CSS */
.map {
  height: 100%;
  border-radius: 3px;
  /* border: 1px solid var(--color-secondary); */
}

/* The popup bubble styling. */
.popupBubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the bubble. */
  background-color: var(--color-white);
  padding: 4px 4px 0px 5px;
  border-radius: 9px;
  /* font-family: 'Rubik', sans-serif; */
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  overflow-y: auto;
  border: 2px solid var(--color-primary);
  /* box-shadow: 0 0 0 2px var(--color-primary), 0.3em 0.3em 0.5em rgba(0, 0, 0, 0.4); */
}

.popupBubble .logo {
  height: 4.8rem;
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popupBubbleAnchor {
  /* Position the div a fixed distance above the tip. */
  position: absolute;
  width: 100%;
  bottom: 8px;
  left: 0;
}

/* This element draws the tip. */
.popupBubbleAnchor::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid var(--color-primary);
}

/* JavaScript will position this div at the bottom of the popup tip. */
.popupContainer {
  cursor: auto;
  height: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  /* The max width of the info window. */
  width: 400px;
}

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
}
