.btn,
.btn:link,
.btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  border-radius: 6px;
  border: none;
  color: var(--color-white);
  background-color: var(--color-tertiary);
  /* background-color: var(--color-primary); */
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  transition: all 0.5s;
}

.btn:hover,
.btn:active {
  transform: scale(1.05, 1.05);
  background-color: var(--color-secondary);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  /* box-shadow: 0 0 0 1px  var(--color-tertiary) inset; */
}

.large {
  font-size: 1.8rem;
  font-weight: 900;
  padding: 1.5rem 3rem;
  margin-right: 1.2rem;
}

.small {
  font-size: 1.4rem;
  font-weight: 800;
  letter-spacing: 0.5px;
  padding: .8rem 1.6rem;
  width: -webkit-fill-available;
  margin: 9px 18px;
}

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
  .large {
    font-size: 1.8rem;
    font-weight: 900;
  }
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
}
