.hero {
  background-image: radial-gradient(
      circle farthest-corner at top left,
      rgb(4, 88, 206, 0.8),
      rgb(59, 130, 246, 0.7),
      rgb(111, 167, 247, 0.6)
    ),
    url(../../public/img/hero-3.JPEG);
  background-size: cover;
  height: 100vh;
}

.heroGrid {
  height: 85%;
  max-width: 130rem;
  display: grid;
  grid-template-columns: 60fr 40fr;
  column-gap: 6rem;
  margin: 0 auto;
  padding-top: 6.4rem;
  align-items: center;
}

.heroBox {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3.2rem;
}

.heading {
  color: var(--color-grey-light-2);
  font-size: 5.2rem;
  font-weight: 900;
  line-height: 0.75;
  text-transform: uppercase;
  margin-bottom: 1.2rem;
  text-shadow: 1px 1px 2px black;
}

.headingScript {
  font-family: 'Permanent Marker', cursive;
  font-weight: 400;
  font-size: 6rem;
  /* font-style: normal; */
  color: var(--color-tertiary);
  text-shadow: 1px 1px 2px black;
}

.subheading {
  color: var(--color-grey-light-2);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.3;
  /* text-transform: uppercase; */
}

.gridColumnShift {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 15px;
  margin-right: -15rem;
  z-index: 5;
}

.heroCard {
  background-color: var(--color-grey-light-1);
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
  position: relative;
  padding-bottom: 9px;
  transition: all 0.5s ease-out;
}

.heroCardTop,
.heroCardRow {
  display: flex;
  padding: 9px 18px;
  align-items: center;
  gap: 9px;
  text-align: left;
}

.heroCardTop {
  background-color: var(--color-grey-light-1);
  font-size: 1.8rem;
  font-weight: 500;
}

.icon {
  color: #2419b2;
  font-size: 2.4rem;
  font-weight: 'bold';
}

.heroCardRow {
  font-size: 1.4rem;
  font-weight: 400;
}

.heroCardBottom {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.horizontalRule {
  width: 100%;
  height: 1px;
  background-color: var(--color-tertiary);
}

.link:link,
.link:visited {
  display: flex;
  align-items: center;
  gap: 9px;
  text-decoration: none;
  color: var(--color-grey-dark-2);
  transition: all 0.5s;
}

.link:hover,
.link:active {
  font-weight: 600;
  /* transform: scale(1.01); */
}
.link:hover,
.link:active .icon {
  font-weight: 'bold';
  /* transform: scale(1.01); */
}

.cardProducts {
  background-image: url(../../public/img/product-hero-7.png);
  background-size: cover;
  background-position: center;
}

.cardRentals {
  background-image: url(../../public/img/sk800-hero-film.jpg);
  background-size: cover;
  background-position: center;
}

.hours {
  color: var(--color-grey-light-2);
  font-size: 2.2rem;
  font-weight: 900;
  letter-spacing: 1px;
  text-shadow: 1px 1px 2px black;
}

.partners {
  height: 15%;
  width: 100%;
}

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
  .heroGrid {
    padding: 6.4rem 2.4rem 0 2.4rem;
  }
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
  .heroBoxText {
    gap: 0.5rem;
  }
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
  .hero {
    height: inherit;
  }

  .heroGrid {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: min-content;
    justify-items: center;
    padding-top: 12.8rem;
  }
  .heroBox {
    padding: 3.2rem 0 0 0;
    justify-content: space-evenly;
    align-items: center;
    gap: 1.2rem;
  }

  .gridColumnShift {
    margin-right: 0;
  }

  .heading,
  .subheading {
    margin-bottom: 2.4rem;
  }

  .hours {
    margin-top: 2.4rem;
  }
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {
  /* .heroGrid {
    padding-top: 12.8rem;
  } */
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
  .heroGrid {
    padding-top: 9.8rem;
  }

  .gridColumnShift {
    grid-template-columns: 1fr;
  }

  .heroCard {
    /* height: 30rem; */
    width: 70vw;
  }

  .cardRentals,
  .cardProducts {
    height: 35rem;
  }

  .hours span {
    display: block;
  }

  .hours span:nth-child(2) {
    display: none;
  }
}