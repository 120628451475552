.header {
  /* font-family: 'Orbitron', sans-serif; */
  display: flex;
  align-items: center;
  height: 6.4rem;
  position: relative;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  border-bottom: 4px solid var(--color-primary);
  background-color: var(--color-white);
}

.logoContainer {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  height: 100%;
  margin: 0 2.4rem;
  /* padding: .4rem 1.2rem; */
  /* width: 20%; */
  /* width: 25%; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* background-color: var(--color-white); */
  /* border: 5px solid var(--color-primary); */
  /* border-right: 6px solid var(--color-primary);
  border-bottom: 6px solid var(--color-primary);
  border-left: 16px solid var(--color-primary); */
  /* border-radius: 15px; */
  /* border-right: 5px solid var(--color-primary); */
  /* clip-path: polygon(0% 0%, 95% 0%, 100% 100%, 0% 100%); */
  z-index: 1;
}

/* .logoContainer::after {
  content: '';
  background-color: var(--color-primary);
  height: 100%;
  width: 10%;
  position: absolute;
  left: 25%;
  clip-path: polygon(0 0, 24% 0, 75% 100%, 54% 100%);
  z-index: 100;
} */

/* .header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 21.5%;
  height: 6.2rem;
  width: 5%;
  z-index: 1;
  transform: skewX(30deg);
  background-color: var(--color-white);
  border-top: 5px solid var(--color-primary);
  border-right: 6px solid var(--color-primary);
  box-shadow: 0.8rem -0.6rem 0.6rem rgba(0, 0, 0, 0.2);
} */

.logo {
  height: 4.8rem;
}

.navContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  /* border-bottom: 5px solid var(--color-primary); */
}

/* MOBILE */
.btn {
  border: none;
  background: none;
  cursor: pointer;

  display: none;
}

/* .icon {
  height: 4.8rem;
  width: 4.8rem;
  color: var(--color-grey-dark-3);
} */

.icon[name='close-outline'] {
  display: none;
}

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
  /* .logo {
    height: 4rem;
  } */
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
  .btn {
    display: block;
    z-index: 1000;
    height: 100%;
    position: absolute;
    top: 0;
    right: 20px;
  }

  .icon[name='close-outline'] {
    display: none;
  }

  .navOpen .icon[name='close-outline'] {
    display: block;
  }

  .navOpen .icon[name='menu-outline'] {
    display: none;
  }
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
  .logo {
    height: 4rem;
  }
}
