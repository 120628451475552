.card {
  background: var(--color-white);
  border-radius: 3px;
  box-shadow: var(--box-shadow);
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-out;
}

/*Hover*/
/* .card:hover {
  transform: scale(1.05, 1.05);
  border-color: var(--color-secondary); 
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.25);
} */

/* .card:hover .card-button {
  transform: translate(-50%, 50%);
  opacity: 1;
} */

.hero {
  height: 80%;
  width: 100%;
  border-radius: 18px;
  background-color: var(--color-white-opacity);
  background-color: var(--color-grey-light-1);
  /* padding: 6px; */
  /* margin: 4rem 0; */
}

.product {
  width: 20rem;
  /* height: 13rem; */
  padding: 1rem;
}

/* .product:hover {
  transform: scale(1.05, 1.05); 
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.25);
} */

.colorSamples {
  height: 40rem;
  width: 25rem;
}

.equipment {
  display: flex;
  height: 27rem;
}

.stamps {
  display: flex;
  height: 25rem;
}

.rental {
  position: relative;
  height: 40rem;
  max-width: 100%;
  padding: 0rem;
}

/* .rental::after {
  content: 'Call for Availability';
  position: absolute;
  top: 35px;
  left: -70px;
  width: 250px;
  height: 40px;
  transform: rotate(-45deg);
  background-color: var(--color-tertiary);
  color: var(--color-white);
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.25px;
  justify-content: center;
  display: flex;
  align-items: center;
  opacity: 0.5;
  z-index: 1;
} */

.card-details {
  color: var(--color-black);
  height: 100%;
  gap: 0.5em;
  display: grid;
  place-content: center;
}

.card-button {
  transform: translate(-50%, 125%);
  width: 60%;
  border-radius: 9px;
  border: none;
  background-color: var(--color-secondary); /*#008bf8;*/
  color: var(--color-white);
  font-size: 1rem;
  padding: 0.5rem 1rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  opacity: 0;
  transition: 0.3s ease-out;
}

.text-body {
  color: var(--color-grey-dark-1);
}

/*Text*/
.text-title {
  font-size: 1.5em;
  font-weight: bold;
}



/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
  .equipment {
    height: 25rem;
  }
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
    .hero {
    display: none;
  }
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {
  .equipment {
    height: auto;
  }

  .stamps {
    height: auto;
  }
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
  .hero {
    max-width: 50rem;
    max-height: 50rem;
  }

  .product {
    width: 40%;
    padding: 0.5rem;
  }

  .equipment {
    flex-direction: column;
  }

  .stamps {
    flex-direction: column;
  }
}
