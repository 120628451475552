/* -----------------------------------------------------------------------

--- 01 TYPOGRAPHY SYSTEM

- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weights
Default: 400
Medium: 500
Semi-bold: 600
Bold: 700

- Line heights
Default: 1
Small: 1.05
Medium: 1.2
Paragraph default: 1.6
Large: 1.8

- Letter spacing
0.5px
0.75px

--- 02 COLORS

- Primary: #2419b2
- Tints: #e9e8f7
- Shades: #070524

- Accents: #3b82f6 #f6af3a
- Greys: #888 #555 #333

--- 05 SHADOWS

Cards: 0 1px 2px rgba(0, 0, 0, 0.2)

0 2.4rem 2.4rem rgba(0, 0, 0, 0.2)
0 0 0 2px rgb(36, 25, 178), 0.3em 0.3em 1em rgba(36, 25, 178, 0.3);
0.8rem -0.6rem 0.6rem rgba(0, 0, 0, 0.2);

--- 06 BORDER-RADIUS

Default: 10px
Small: 5px

--- 07 WHITESPACE

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128


*/

:root {
  --color-primary: #2419b2;
  --color-primary-light: #e9e8f7;
  --color-primary-dark-1: #1d148e;
  --color-primary-dark-2: #160f6b;
  --color-primary-opacity: rgb(36, 25, 178, 0.8);
  --color-secondary: #3b82f6;
  --color-secondary-light: #ebf3fe;
  --color-secondary-dark: #0c1a31;
  --color-secondary-opacity: rgb(59, 130, 246, 0.8);
  --color-tertiary: #ffbe0b;
  --color-tertiary-light: #ffd768;
  --color-tertiary-dark: #cb9500;
  --color-grey-light-1: #f7f7f7;
  --color-grey-light-2: #eee;
  --color-grey-light-3: #ccc;
  --color-grey-dark-1: #777;
  --color-grey-dark-2: #555;
  --color-grey-dark-3: #333;
  --color-white: #fff;
  --color-white-opacity: rgba(255, 255, 255, 0.8);

  --color-black: #000;
  --box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
}

/* -----------------------------------------------------------------------
- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
----------------------------------------------------------------------- */

/* BASE STYLING */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: 1px solid red; */
}

*:focus {
  outline: none;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Lato', sans-serif;
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  font-weight: 400;
  line-height: 1.6;
  color: var(--color-grey-dark-3);
  /* letter-spacing: 1px; */
  position: relative;
}

button {
  font-family: inherit;
}

/* TYPOGRAPHY */

.heading-primary,
.heading-secondary,
.heading-tertiary {
  text-transform: uppercase;
  margin-bottom: 6rem;
}

.heading-primary {
  font-size: 6rem;
  font-weight: 900;
  letter-spacing: 0.2rem;
}

.heading-secondary {
  font-size: 3.5rem;
  font-weight: 900;
  letter-spacing: 0.1rem;
}

.heading-tertiary {
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 0;
}

.subheading {
  color: var(--color-grey-dark-1);
  font-size: 1.4rem;
  font-weight: 600;
  /* margin-bottom: .2rem; */
  text-transform: uppercase;

  /* display: block;
  font-size: 2.4rem;
  font-weight: 600;
  color: var(--color-secondary);
  text-transform: uppercase;
  margin: 1.2rem 0; */
}

.heading-dark {
  background-image: linear-gradient(
    to right bottom,
    var(--color-secondary),
    var(--color-primary)
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.heading-light {
  color: var(--color-grey-light-1);
}

.paragraph {
  font-size: 1.6rem;
  /* hyphens: auto; */
}

.paragraph:not(:last-child) {
  margin-bottom: 3rem;
}

.center-text {
  text-align: center;
}

strong {
  font-weight: 600;
  text-transform: uppercase;
}

/* LISTS */

.list {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}

li {
  list-style: none;
}

.list-item {
  font-size: 1.6rem;
  /* font-weight: 500; */
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  text-align: left;
  gap: 8px;
}

.list-icon {
  width: 3rem;
  height: 3rem;
  color: var(--color-primary);
}

/* LAYOUT */

.container {
  max-width: 130rem;
  padding: 6rem 0;
  margin: 0 auto;
}

.page-header {
  background: linear-gradient(
    to right bottom,
    var(--color-secondary),
    var(--color-primary)
  );
  padding: 3em;
}

.flex {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}

.grid {
  display: grid;
  column-gap: 4rem;
  row-gap: 3rem;
}

/* .grid:not(:last-child) {
  margin-bottom: 9.6rem;
} */

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}

.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.grid--5-cols {
  grid-template-columns: repeat(5, 1fr);
}

.grid--center-v {
  align-items: center;
}

.grid-heading {
  grid-row: 1/ -1;
}

/* LINKS */

.link:link,
.link:visited {
  font-size: 1.2rem;
  display: inline-block;
  color: var(--color-primary);
  text-decoration: none;
  /* border-bottom: 1px solid currentColor; */
  padding-bottom: 2px;
  transition: all 0.3s;
}

.link:hover,
.link:active {
  color: var(--color-primary-dark-1);
  border-bottom: 1px solid transparent;
}

/* HELPER/SETTINGS CLASSES */

.margin-right-sm {
  margin-right: 1.6rem !important;
}

.margin-bottom-sm {
  margin-bottom: 2.4rem !important;
}

.margin-bottom-md {
  margin-bottom: 4.8rem !important;
}

.margin-bottom-lg {
  margin-bottom: 8rem !important;
}

/* Phospher Icons */
.ph-fill,
.ph {
  font-size: 3rem;
  background: linear-gradient(
    to right bottom,
    var(--color-primary),
    var(--color-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

/* START GOOGLE MAPS CARD CSS */
#map {
  height: 100%;
  border-radius: 12px;
  /* border: 1px solid var(--color-secondary); */
}

/* The popup bubble styling. */
.popup-bubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the bubble. */
  background-color: var(--color-white);
  padding: 5px 15px 2px 15px;
  border-radius: 5px;
  font-family: 'Rubik', sans-serif;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  overflow-y: auto;
  border: 2px solid var(--color-secondary);
  box-shadow: 0 0 0 2px var(--color-primary),
    0.3em 0.3em 0.5em rgba(0, 0, 0, 0.4);
}

.popup-bubble .logo {
  height: 9.6rem;
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popup-bubble-anchor {
  /* Position the div a fixed distance above the tip. */
  position: absolute;
  width: 100%;
  bottom: 8px;
  left: 0;
}

/* This element draws the tip. */
.popup-bubble-anchor::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid var(--color-primary);
}

/* JavaScript will position this div at the bottom of the popup tip. */
.popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  /* The max width of the info window. */
  width: 300px;
}
