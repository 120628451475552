.noPage {
  background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url(../../public/img/stamp-bg-3.svg);
  background-size: 100%;
  height: 100vh;
}

.noPageGrid {
  max-width: 130rem;
  height: 100%;
  display: grid;
  grid-template-columns: 40fr 60fr;
  column-gap: 6rem;
  margin: 0 auto;
  padding: 6.4rem 0;
  align-items: center;
}

.gridCol {

}

.error {
  font-size: 5.2rem;
  font-weight: 700;
  margin-bottom: 2.4rem;
}

.message {
  font-size: 2.2rem;
  margin-bottom: 2.4rem;
}

.btn {
  color: var(--color-white);
  font-size: 2.4rem;
}

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
}
