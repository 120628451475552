.composition {
  position: relative;
}

.photoComposition {
  width: 35rem;
  height: 25rem;
  border-radius: 12px;
  position: absolute;
  z-index: 10;
  transition: all 0.5s;
  outline-offset: 4px;
}

.photoComposition:hover {
  outline: 4px solid var(--color-primary);
  transform: scale(1.05) translateY(-0.5rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 20;
}

.composition:hover .photoComposition:not(:hover) {
transform: scale(0.95);
}

.photo1 {
  left: 0;
  top: 20%;
}

.photo2 {
  right: 0;
  top: 25%;
}

.photo3 {
  left: 20%;
  top: 50%;
}

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
  .photoComposition {
    width: 32rem;
    height: 22rem;
  }
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
  .photoComposition {
    display: none;
  }
}
