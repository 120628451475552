/* CONTACT FORM CSS */

.form {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  border-radius: 12px;
  position: relative;
}

.formTitle {
  font-size: 28px;
  color: var(--color-primary);
  font-weight: 600;
  letter-spacing: -0.5px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 30px;
}

.formTitle::before,
.formTitle::after {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  border-radius: 50%;
  left: 0px;
  background-color: var(--color-secondary);
}

.formTitle::before {
  width: 18px;
  height: 18px;
  background-color: var(--color-primary);
}

.formTitle::after {
  width: 18px;
  height: 18px;
  animation: pulse 1s linear infinite;
}

.message {
  color: var(--color-white);
  font-size: 1.4rem;
  letter-spacing: 0.25px;
  padding: 8px 0;
}

.flex {
  display: flex;
  width: 100%;
  gap: 1.4rem;
}

.form label {
  position: relative;
  width: 100%;
}

.form label .input {
  width: 100%;
  padding: 10px 10px 20px 10px;
  outline: 0;
  border: 1px solid rgba(105, 105, 105, 0.397);
  border-radius: 9px;
}

.form label .input + span {
  position: absolute;
  left: 10px;
  top: 15px;
  color: var(--color-grey-dark-2);
  font-size: 1.4em;
  cursor: text;
  transition: 0.3s ease;
}

.form label .input:placeholder-shown + span {
  top: 15px;
  font-size: 0.9em;
}

.form label .input:focus + span,
.form label .input:valid + span {
  top: 30px;
  font-size: 0.8em;
  font-weight: 600;
}

.form label .input:valid + span {
  color: var(--color-secondary);
}

.submit {
  border: none;
  outline: none;
  background-color: var(--color-primary);
  padding: 10px;
  margin-top: 10px;
  border-radius: 9px;
  color: var(--color-white);
  font-size: 16px;
  transform: 0.3s ease;
}

.submit:hover {
  background-color: var(--color-secondary);
  box-shadow: inset 0 0 0 1px var(--color-white);
}

@keyframes pulse {
  from {
    transform: scale(0.9);
    opacity: 1;
  }

  to {
    transform: scale(1.8);
    opacity: 0;
  }
}

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
}
